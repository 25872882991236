/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'

// Elements
import BlockDefault from 'components/elements/BlockDefault'
import HeroAlt from 'components/elements/HeroAlt'
import ButtonDefault from 'components/elements/ButtonDefault'

// Icons
import Time from 'img/icon/time-secondary.inline.svg'
import Next from 'img/icon/next-main.inline.svg'

// Data
import blogInfo from 'src/graphql/blogInfo'

const Container = styled.div`
  position: relative;
  z-index: 2;

  @media (min-width: 1200px) {
    max-width: 900px;
  }
`

const Title = styled.h1`
  font-weight: ${props => props.theme.font.weight.xl};
  color: ${props => props.theme.color.text.contrast};

  @media (min-width: 992px) {
    font-size: ${props => props.theme.font.size.xxl};
  }

  @media (max-width: 991px) {
    font-size: ${props => props.theme.font.size.l};
  }
`

const ReadTime = styled.div`
  font-weight: ${props => props.theme.font.weight.s};
  color: ${props => props.theme.color.text.secondary};

  & > svg {
    height: 18px;
    width: 18px;
    margin-right: 10px;
    position: relative;
    bottom: 2px;
  }

  & > span {
    display: inline-block;
    margin-left: 5px;
  }
`

const BackButton = styled(Link)`
  font-size: ${props => props.theme.font.size.sm};
  font-weight: ${props => props.theme.font.weight.l};
  color: ${props => props.theme.color.text.main};
  border: 2px solid ${props => props.theme.color.text.main};
  padding: 8px 15px;
  border-radius: 25px;

  & svg {
    height: 20px;
    width: 20px;
    transform: rotate(180deg);
    margin-right: 10px;
    position: relative;
    bottom: 2px;
  }

  &:hover {
    color: ${props => props.theme.color.text.main};
    text-decoration: underline;
  }
`

const Stripe = styled.div`
  background-color: ${props => props.theme.color.text.main};
  height: 1px;
  width: 100%;
`

const ButtonInfo = styled.div`
  color: ${props => props.theme.color.text.secondary};
`

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPost(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      
      acf {
        banner {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920)
                openGraph: fluid(maxWidth: 1500, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        meta {
          readtime
          description
          button {
            title
            url
          }
          button_info
        }
      }

      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { path, title, yoast_meta: yoast, acf },
  },
}) => {
  const blogFields = blogInfo.get()

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path}
        image={{ url: acf.banner.image.localFile && acf.banner.image.localFile.childImageSharp.openGraph.src }}
        type="article"
      />

      <section>
        <HeroAlt small image={acf.banner.image} />
      </section>

      <section className="mt-n5 pb-5">
        <Container className="container mt-n5">
          <BlockDefault bigBorderRadius>
            <div>
              <BackButton to={blogFields.path}>
                <Next />
                {blogFields.acf.overview.back_button_title}
              </BackButton>
            </div>
            <div className="px-lg-5 mt-5">
              <Title>
                {title}
              </Title>
              <ReadTime className="mt-3">
                <Time />
                {acf.meta.readtime}
                <span>
                  {blogFields.acf.overview.readtime_title}
                </span>
              </ReadTime>
            </div>
            <div className="px-lg-3 mt-4 mt-lg-5">
              <Stripe />
            </div>
            <div className="px-lg-5 mt-4 mt-lg-5">
              <ParseContent content={acf.meta.description} />

              <div className="mt-5 pt-lg-5 text-center">
                <ButtonInfo className="mt-3 font-size-xm font-weight-s">
                  {acf.meta.button_info}
                </ButtonInfo>

                <ButtonDefault className="mt-3" to={acf.meta.button.url} type="next">
                  {acf.meta.button.title}
                </ButtonDefault>
              </div>
            </div>
          </BlockDefault>
        </Container>
      </section>
    </Layout>
  )
}

export default PageTemplate
